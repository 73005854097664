<script>
// import io from "socket.io-client"
import TopStrony from './components/TopStrony.vue'
import MainCiasteczka from "./components/MainCiasteczka.vue"
// import StronaPolozenie from './components/StronaPolozenie.vue'
import SrodekStrony from './components/SrodekStrony.vue'
import DolStrony from './components/DolStrony.vue'


export default {
	name: 'App',
	data(){
		return{
			// socket: {},
			VModel: {},
			WyswietlCiasteczka: false,
			SciezkaLokalizacji: '/',
			IdLokalizacji: null,
			ProcentObserwacji: 0,
			TimerCzasObserwacji: null,
			CzasObserwacji: 0,
			TabelaObserwacji: [],
			TabelaIloscDanych: 0,
			TabelaWysylka: false
		}
	},
	components: {
		// HelloWorld,
		TopStrony,
		MainCiasteczka,
		// StronaPolozenie,
		SrodekStrony,
		DolStrony
	},
	computed: {
		PathName(){
			return window.location.pathname
		}
	},
	methods:{
		/** To są testowe funkcje */
		ObslugaPoprzezSockets(){
			// Na razie ta funkcja jest nieużywana, ale zostawiam ją aby można było w razie czego wrócić do tematu
			let parametr = window.location.href.split('?')[1]
			if (parametr != undefined){
				console.warn("zdefiniowany " + parametr)
				let klucz = parametr.split('=')[0]
				let identyfikator=parametr.split('=')[1]
				if (klucz == "site"){
					// console.warn("identyfikator " + identyfikator)
					document.cookie="klucz="+identyfikator+"; expires=Fri, 31 Dec 9999 00:00:01 GMT"
				}
			}
			if (document.cookie != ""){
				let informacja ={}
				informacja.lokacja = window.location.pathname
				informacja.ciastko=document.cookie
				this.socket.emit("info", informacja )
			}
		},
		/** ta funkcja jest zbędna, ale na razie ją zostawiam, być może przyda się */
		makeid(length) {
			let result = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const charactersLength = characters.length;
			let counter = 0;
			while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
			}
			return result;
		},
		/** Ta funkcja ma za zadanie Odzyskać Nazwę Domenty do której łączy się przeglądarka i pousuwać z niej przedrostki www*/
		Domena() {
            let hostname = window.location.hostname
            let cleanDomain = hostname.replace(/^www\./, '')
            // console.debug('Domena:',  cleanDomain)
            return cleanDomain
        },
		Port(){
			if (this.Domena() == 'localhost'){
				return ':1890'
			} 
			return ':'+window.location.port
		},
		/** Funkcja ma za zadanie Zamienić istniejące ciasteczka do formatu JSON */
		CookieToJSON(param){
			param = param.replace(/\s/g, "")	//Usunięcie wszystkich spacji z tekstu
			// console.debug(param)
			let Powrot={}
			let Tab = param.split(';')
			this.ciastko = 
			Tab.forEach(element => {
				let Rozbicie = element.split('=')
				Powrot[Rozbicie[0]] = Rozbicie[1]
			})
			return Powrot
		},
		/** Funkcja ma za zadanie pobrać przychodące parametry strony i zamienić je na JSON*/
		SiteParamToJSON(param){
			let Powrot={}
			let ParametryString = param.split('?')[1]
			if (ParametryString != undefined){
				let Tab = ParametryString.split('&')
				Tab.forEach(element => {
					let Rozbicie = element.split('=')
					Powrot[Rozbicie[0]] = Rozbicie[1]
				})
			}
			return Powrot
		},
		/** Funkcja ma za zadanie utworzyć Ciasteczko */
		UtworzCiastko(klucz, wartosc){
			// Tutaj Bardzo ważna jest ta kropka przed document.Domena() Powoduje ona, że ciasteczko tworzone jest dla
			// subdomen również dla www.domena.pl a nie tylko dla domena.pl
			document.cookie=klucz+"="+wartosc+"; expires=Fri, 31 Dec 9999 00:00:01 GMT; path=/; domain=."+document.Domena()
		},
		/** Funkcja obsługująca mój mechanizm GET */
		MojeApiGET(sciezka, funkcja, params){
			// Jeżeli Parametry nie zostały podane, to zamieniamy to na pusty obiekt
			if (params == undefined) params = {}

			// To ustawienie w połączeniu z ustawieniem PROXY w vue.config.js pozwala przekierowywać ruch API na inny serwer
			const url = document.Protokol+'//'+document.Domena()+document.Port+sciezka
			// const url = 'http://' + location.hostname + ':1890'+sciezka

			console.debug('Lokalizacja:', url)

			// Tworzenie adresu URL z parametrami
            const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&')
            const fullUrl = `${url}?${queryString}`

            fetch(fullUrl)
                .then(response => response.json())
                .then(data => {
                    // Tutaj Wywołujemy Funckję Obsługującą to Zapytanie
                    funkcja(data)
                })
                .catch(error => {
                    // Tutaj możesz obsłużyć błąd, jeśli wystąpił
                    console.error(error)
                })
        },
		/** Funkcja obsługująca mój mechanizm POST */
		MojeApiPOST(sciezka, funkcja, params){
			const url =  document.Protokol+'//'+document.Domena()+document.Port+sciezka
			// const url = 'http://' + location.hostname + ':1890'+sciezka

			fetch(url, {
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json'
				}
				})
				.then(response => response.json())
				.then(data => {
					// console.debug('Odpowiedź serwera:', data)
					funkcja(data)
				})
				.catch(error => {
					console.error('Błąd:', error)
				})
		},
		/** Pierwszą rzeczą jest pobranie ciasteczka z Backend, ponieważ to na jego podstawie będą wykonywane pozostałe operacje*/
		PobierzCiasteczko(){
			// Sprawdzenie czy posiadamy ciasteczko
			let Ciasteczka = this.CookieToJSON(document.cookie)
			if ('Ciasteczko' in Ciasteczka){
				this.WyslijParametry()
				this.SprawdzAkceptacjeCiasteczek()
			} else {
				let Wysylka = {}
				Wysylka['userAgent'] = navigator.userAgent
				Wysylka['domena'] = this.Domena()
				this.MojeApiGET('/cookies/getCookie', this.PowrotPobierzCiasteczko, Wysylka)
			}
			
		},
		/** Obsługa pobrania ciasteczek */
		PowrotPobierzCiasteczko(param){
			console.debug('PowrotPobierzCiasteczko: ', param)
			if ('cookie' in param){
				console.debug('Otrzymałem Ciasteczko')
				this.UtworzCiastko('Ciasteczko', param.cookie)
				this.WyslijParametry()
				this.SprawdzAkceptacjeCiasteczek()
			}
		},
		/** Funkcja ma za zadanie wysłać parametry z wejścia na stronę Do Backendu */
		WyslijParametry(){
			/** 
			 * Ta funkcja będzie wysyłała poszczególne parametry typu użytkownik, a w przyszłości linki z Youtube lub innych
			 * elementów
			 */
			// Najpierw sprawdzenie czy na prawdę jest ciasteczko bo bez tego nic nie pójdzie
			let Ciasteczka = this.CookieToJSON(document.cookie)
			if ('Ciasteczko' in Ciasteczka){
				let Parametry = this.SiteParamToJSON(window.location.href)
				// console.debug('Parametry Strony:', Parametry)
				// jeżeli jest w parametrach użytkownik to wysyłamy
				if ('user' in Parametry) {
					const Wysylka = { 'user': Parametry.user, 'cookie': Ciasteczka.Ciasteczko }
					this.MojeApiPOST('/cookies/saveUser', this.PowrotWyslijParametry, Wysylka)
				}
			}
		},
		/** Funkcja obsługująca powrót wysyłki parametrów, ale tutaj na razie nic nie robię */
		/* eslint-disable no-unused-vars */
		PowrotWyslijParametry(param){
			// console.debug('PowrotWyslijParametry:', param)
		},
		/** Ta funkcja ma za zadanie sprawdzić jaki jest status akceptacji ciasteczek przez użytkownika */
		SprawdzAkceptacjeCiasteczek(){
			let Ciasteczka = this.CookieToJSON(document.cookie)
			if ('Ciasteczko' in Ciasteczka){
				const Wysylka = {'cookie': Ciasteczka.Ciasteczko }
				this.MojeApiGET('/cookies/getAkceptacjaCiastka', this.PowrotSprawdzAkceptacjeCiasteczek, Wysylka)
			}
		},
		PowrotSprawdzAkceptacjeCiasteczek(param){
			// console.debug('Stan Akceptacji Ciasteczek:', param)
			if (param.status != 1) {
				this.WyswietlCiasteczka = true
			} else {
				this.WyswietlCiasteczka = false	
			}
		},
		/** Funkcja Ma za zadanie zaakceptować ciasteczka */
		ZaakceptujCookie(){
			let Ciasteczka = this.CookieToJSON(document.cookie)
			if ('Ciasteczko' in Ciasteczka){
				const Wysylka = {'cookie': Ciasteczka.Ciasteczko }
				this.MojeApiGET('/cookies/akceptacjaCiastka', this.PowrotZaakceptujCookie, Wysylka)
			}
		},
		/* eslint-disable no-unused-vars */
		PowrotZaakceptujCookie(param){
			this.SprawdzAkceptacjeCiasteczek()
		},
		/** 
		 * Ta funkcja ma za zadanie wysłać bierzącą lokalizację 
		 * Tutaj jest mechanizm, który ma za zadanie poczekać w razie czego na pojawienie się ciasteczka
		*/
		WyslijLokalizacje(Sciezka){
			// console.debug('Funkcja WyslijLokalizacje:', Sciezka)
			this.SciezkaLokalizacji = Sciezka
			this.MechWyslijLokalizacje()
		},
		MechWyslijLokalizacje(){
			// console.debug('Funkcja MechWyslijLokalizacje')
			let Ciasteczka = this.CookieToJSON(document.cookie)
			if ('Ciasteczko' in Ciasteczka){
				const Wysylka = {
					'cookie': Ciasteczka.Ciasteczko, 
					'sciezka': this.SciezkaLokalizacji,
					'oknoWidth': window.innerWidth,
					'oknoHeight': window.innerHeight
				}
				this.MojeApiGET('/cookies/wysylkaLokalizacji', this.ObsluzLokalizacje, Wysylka)
			} else {
				// Jeżeli nie znaleziono ciasteczka to prubuj ponownie
				window.setTimeout(this.MechWyslijLokalizacje, 500)
			}
		},
		ObsluzLokalizacje(param){
			this.IdLokalizacji = param.insertId
		},
		/** Obsługa śledzenia pozycji suwaka. Tutaj wytworzony jest mechanizm cząstkowego przesyłania informacji
		 * i zbierania jej w trakcie przesyłania. Mechanizm przez cały czas zbiera info do tabeli i wysyła ją cząstkowo
		 * dopuki nie odbierze zwrotu
		*/
		ListenerScrollPercentage(){
			const percentScrolled = this.getScrollPercentage()
			// Tutaj oczekujemy tylko na zmianę a nie na wartości podczas scrolowania
			if (this.ProcentObserwacji != percentScrolled){
				this.ProcentObserwacji = percentScrolled
				// console.debug(`Procent przewinięcia: ${percentScrolled}% Czas: ${this.CzasObserwacji}`)
				const DanePozycji = {'Proc': percentScrolled, 'Czas': this.CzasObserwacji}
				// Zerujemy czas obserwacji, ponieważ jest on składany na serwerze
				this.CzasObserwacji = 0
				// Zapisujemy to do tabeli i jeżeli akurat nie trwa wysyłka to to wysyłamy
				this.TabelaObserwacji.push(DanePozycji)
				this.TabelaIloscDanych ++
				// Jeżeli akurat nic nie jest wysyłane to można wysłać dane
				// Najpier jednak należy sprawdzić, czy posiadamy ciasteczko i ID Strony do której wysyłamy
				// W takim wypadku nic nie wysyłamy ale zbieramy informacje
				if (this.IdLokalizacji == null) return
				const Ciasteczka = this.CookieToJSON(document.cookie)
				if ( !('Ciasteczko' in Ciasteczka)) return
				if (!this.TabelaWysylka){
					this.WyslijTabele()
				}
			}
		},
		/* eslint-disable no-unused-vars */
		PowrotApiListener(param){
			// console.debug('Powrot')
			this.TabelaWysylka = false
			// Jeżeli po powrocie coś jest w tabeli to należy jeszcze raz to wysłać
			if (this.TabelaObserwacji.length > 0){
				this.WyslijTabele()
			}
		},
		/** Funkcja ma za zadanie wysłać komplet informacji */
		WyslijTabele(){
			const DaneWysylka = {
				Ciasteczko: this.CookieToJSON(document.cookie).Ciasteczko,
				IdLokalizacji: this.IdLokalizacji,
				Lokalizacja: this.SciezkaLokalizacji,
				Ilosc: this.TabelaIloscDanych,
				Dane: this.TabelaObserwacji
			}
			// console.debug('WYSYLKA:', DaneWysylka)
			this.MojeApiPOST('/cookies/wyslijScrool', this.PowrotApiListener, DaneWysylka)
			this.TabelaWysylka = true
			this.TabelaObserwacji = []
			this.TabelaIloscDanych = 0
		},
		/** Czas pobytu na stronie jest liczony, a to jest timer do jego liczenia */
		ObslugaTimerCzasObserwacji(){
			this.CzasObserwacji += 1
		},
		/** Funkcja ma za zadanie obliczyć procentową pozycję suwaka w przeglądarce */
		getScrollPercentage() {
			const scrollTop = window.scrollY // Pozycja przewinięcia w pionie
			const docHeight = document.body.scrollHeight // Całkowita wysokość dokumentu
			const winHeight = window.innerHeight // Wysokość okna przeglądarki
			// Obliczanie procentu przewinięcia
			const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100
			return Math.round(scrollPercent/5)*5 // Zaokrąglamy do najbliższej liczby całkowitej
		}
	},
	created(){
		// this.socket = io("http://192.168.43.180:3000")
		// this.socket = io("http://hicad.pl:8000")
		console.debug(window.location.href)
		document.Domena = this.Domena
		document.Protokol = window.location.protocol
		// document.Port = window.location.port
		// document.Port = 1890
		document.Port = this.Port()
		document.MojeApiGET = this.MojeApiGET
		document.MojeApiPOST = this.MojeApiPOST
		document.WyslijLokalizacje = this.WyslijLokalizacje
		
		this.PobierzCiasteczko()
		window.addEventListener('scroll', this.ListenerScrollPercentage)
		this.TimerCzasObserwacji = setInterval(this.ObslugaTimerCzasObserwacji, 100)
		
	},
	mounted(){
		// this.ObslugaPoprzezSockets()
	}
}

</script>

<template>
	<div id="app">
		<b-container id="app_container" style="background-color: rgb(250, 250, 250);">
			<!-- {{ PathName }} -->
			<TopStrony v-model="VModel"></TopStrony>
			<!-- <StronaPolozenie v-model="VModel"></StronaPolozenie> -->
			<MainCiasteczka v-if="WyswietlCiasteczka" v-on:onKlik="ZaakceptujCookie()"></MainCiasteczka>
			<SrodekStrony v-model="VModel"></SrodekStrony>
			<DolStrony></DolStrony>
			<!-- {{ VModel }} -->
		</b-container>
	</div>
</template>

